import { environment } from './../../../environments/environment';
import { Model } from './../../shared/model/model';

export const supportedCurrencies = [
  {
    key: "inr",
    value: "INR",
    icon: "rupee"
  },
  {
    key: "usd",
    value: "USD",
    icon: "dollar"
  },
  {
    key: "gbp",
    value: "GBP",
    icon: "pound"
  },
  {
    key: "cad",
    value: "CAD",
    icon: "dollar"
  },
];

export const currencyIcon = {
  inr: "rupee",
  usd: "dollar",
  gbp: "pound",
  cad: "dollar"
};

export const categories = [
  {
    value: "test-package",
    label: "Test Package",
  },
  {
    value: "subscription-package",
    label: "Subscription Package",
  },
  {
    value: "webinar",
    label: "Webinar",
  }
]

export const mockTests = [
  {
    id: 1,
    name: "Free CELPIP-General LS Practice Tests (Starter Set)"
  },
  {
    id: 2,
    name: "Free CELPIP-General Practice Tests (Starter Set)"
  }
];

export const validities = [1, 2, 3, 6, 9, 12, 18, 24, 36, -1]; // -1 represents for lifetime validity

export interface PackageInterface {
  id: number;
  title: string;
  description: string;
  image: string;
  categories: string;

  regularPrice: number;
  sellingPrice: number;
  currency: string;
  allotedTests: string;
  allotedFreeTests: string;
  allotedCourses: string;
  webinars: any[];
  createdAt: string;
  createdBy: string;
  status: string;
  isDeletable: boolean;
  isUpdatable: boolean;
  validity: number;
  rating: number;
  services?: string;
  currencyByPrice?: string;
  config?: any;
}

export class Package extends Model {
  id: number;
  title: string;
  description: string;
  image: string | ArrayBuffer | null;
  original_image: string;
  categories: string;
  status: string;
  regularPrice: number;
  sellingPrice: number;
  currency: string;
  createdAt: string;
  createdBy: string;
  allotedTests: string[];
  allotedFreeTests: string[];
  allotedCourses: string[];
  webinars: any[]

  totalAllotedTests: number;
  totalAllotedFreeTests: number;
  discount: number;
  isDeletable: boolean;
  isUpdatable: boolean;
  validity: number;
  services?: string
  currencyByPrice?: string;
  rating: number;
  config?: any;

  constructor(_package: PackageInterface = {
    id: 0,
    title: "",
    description: "",
    image: "",
    categories: "",

    status: "",
    regularPrice: 0,
    sellingPrice: 0,
    currency: "",
    createdAt: "",
    createdBy: "",
    allotedTests: "[]",
    allotedFreeTests: "[]",
    allotedCourses: "[]",
    webinars: [],
    isDeletable: false,
    isUpdatable: false,
    validity: 3,
    services: '',
    currencyByPrice: '',
    rating: 0,
    config: {}
  }) {
    super();

    this.id = _package.id;
    this.title = _package.title;
    this.description = _package.description;
    this.original_image = _package.image;
    this.image = `${environment.api}${environment.assets}packages/${_package.image}`;
    this.categories = _package.categories;
    this.webinars = _package.webinars;
    this.regularPrice = _package.regularPrice;
    this.sellingPrice = _package.sellingPrice;
    this.currency = _package.currency;
    this.createdAt = this.toLocale(_package.createdAt);
    this.createdBy = _package.createdBy;
    this.allotedTests = JSON.parse(_package.allotedTests);
    this.allotedFreeTests = JSON.parse(_package.allotedFreeTests);
    this.allotedCourses = JSON.parse(_package.allotedCourses);

    this.status = _package.status;
    this.totalAllotedTests = this.allotedTests.length;
    this.totalAllotedFreeTests = this.allotedFreeTests.length;
    this.discount = this.calculatePercentage(this.regularPrice, this.sellingPrice);

    this.isDeletable = _package.isDeletable;
    this.isUpdatable = _package.isUpdatable;
    this.validity = _package.validity;
    this.services = _package.services;
    this.currencyByPrice = _package.currencyByPrice;
    this.rating = _package.rating;
    this.config = _package.config;
  }

  public calculatePercentage(regularPrice: number, sellingPrice: number) {
    const costDifference = regularPrice - sellingPrice;

    return regularPrice ? parseInt(((costDifference / regularPrice) * 100).toString()) : regularPrice;
  }
}

